import { InjectionToken } from "@angular/core";

export const VIEWER = new InjectionToken<string>('viewer');
export const CRAWLER_AGENTS = [
    'googlebot', 'yandexbot', 'yahoo', 'bingbot',
    'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot',
    'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain',
    'pinterest/0.', 'developers.google.com/+/web/snippet',
    'slackbot', 'vkshare', 'w3c_validator', 'redditbot', 'applebot',
    'whatsapp', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview',
    'nuzzel', 'discordbot', 'google page speed', 'postmanruntime', 'postmanruntime/7.33.0'
];